<template>
  <div class="outter">
    <div class="ad_banner">
      <img src="../assets/image/page3/banner.png" alt="">
    </div>
    <div class="wrap insure">
      <div class="container">
        <div class="title_light">
          <img src="../assets/image/page3/hb.png" alt="">
        </div>
        <div class="headtitle">
          <span>保险公司</span>
        </div>
        <div class="logo">
          <img src="../assets/image/page3/logo1.png" alt="">
        </div>
      </div>
    </div>
    <div class="drug">
      <div class="container">
        <div class="headtitle">
          <span>连锁药店</span>
        </div>
        <div class="logo">
          <img src="../assets/image/page3/logo2.png" alt="">
        </div>
      </div>
    </div>
    <div class="other container">
      <div class="headtitle">
        <span>其他服务</span>
      </div>
      <div class="logo">
        <img src="../assets/image/page3/logo3.png" alt="">
      </div>
    </div>
    <div class="bank">
      <div class="container">
        <div class="headtitle">
          <span>银行</span>
        </div>
        <div class="logo">
          <img src="../assets/image/page3/logo5.png" alt="">
        </div>
      </div>
    </div>
    <div class="text">*以上仅为部分合作伙伴，排名不分先后</div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.insure {
  padding: 70px 0;

  .title_light img {
    width: 187px;
    height: 74px;
  }
}
.drug{
  padding: 60px 0;
  background-color: #f9f9f9;
}
.bank {
  padding: 75px 0 60px;
  background-color: #f9f9f9;
  
  .logo {
    padding-left: 0;
  }
}
.other {
  padding: 70px 0;
}
.imgbg {
  width: 100%;
  background: url('../assets/image/page3/logo_bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 1200px;
}
.darkbg {
  width: 100%;
  min-width: 1200px;
  background: #f9f9f9;
}
.headtitle {
  width: 120px;
  height: 51px;
  padding: 0 0 0 22px;
  background: #00B095;
  margin: 0 0 60px;
  line-height: 51px;
  text-align: left;
  font-size: 19px;
  color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
}
.logo {
  width: 1100px;
  box-sizing: border-box;
  img {
    width: 100%;
  }
}
.text {
  color: #bababa;
  text-align: center;
  padding: 40px 0 70px;
  font-size: 14px;
}
</style>
